import { PageData } from '@hooks/usePageMetadata'
import { defaultConfig as queryConfig } from '@utils/react-query'
import { GetServerSideProps } from 'next'
import { FunctionComponent } from 'react'
import {
  dehydrate,
  DehydratedState,
  QueryClient,
  useQueryClient,
} from '@tanstack/react-query'
import JSONRenderer from '@components/JSONRenderer'
import {
  composeKropkaConfig,
  getIsBlickPlusOfferPage,
  getRSSPageInfo,
  initializeQueryClientKeys,
  prefetchCUELiveData,
  prefetchPage,
  prefetchPlaylist,
  prefetchSkeleton,
  prefetchSportData,
  processNonOverwritingKeyMap,
  redirectRSS,
  requestContainsProblematicQueryParam,
  serveRSSFeed,
  STATUS_404,
} from '@utils/server'

export interface AdSkeleton {
  version?: string
  environment?: 'staging' | 'prod'
}

export interface CatchAllPageServerProps {
  isError: boolean
  isBlickPlusOfferPage: boolean
  dehydratedQueryState: DehydratedState
  keysThatShouldNotOverwriteClientCacheIfExists: Record<string, true>
  documentProps?: {
    isSwissGeolocation: boolean
    disableThirdPartyScripts: boolean
    kropkaConfig: Record<string, unknown>
    preconnectToCUELiveDomain: boolean
    preconnectToSportDomain: boolean
    preconnectToSportDomainV2: boolean
  }
}

const CatchAllPage: FunctionComponent = () => {
  const queryClient = useQueryClient()

  const pageData = queryClient.getQueryData<PageData>(['page'])

  return <JSONRenderer>{pageData?.children ?? []}</JSONRenderer>
}

export default CatchAllPage

export const getServerSideProps: GetServerSideProps<
  CatchAllPageServerProps
> = async (context) => {
  if (requestContainsProblematicQueryParam(context)) {
    return STATUS_404
  }

  const rssContentInfo = await getRSSPageInfo(context)

  if (rssContentInfo.isRSS) {
    if (rssContentInfo.isRSSNotFound) {
      return STATUS_404
    } else if (rssContentInfo.isRSSRedirect) {
      return redirectRSS(context)
    } else {
      return serveRSSFeed({
        feedRSS: rssContentInfo.feedRSS,
        context,
      })
    }
  }

  const queryClient = new QueryClient(queryConfig)

  const [skeletonFetchResult, pageFetchResult] = await Promise.allSettled([
    prefetchSkeleton(queryClient),
    prefetchPage({ queryClient, context }),
  ])

  if (skeletonFetchResult.status === 'rejected') {
    queryClient.clear()
    return STATUS_404
  }

  if (pageFetchResult.status === 'rejected') {
    queryClient.clear()
    return STATUS_404
  }

  const pageFetchResultValue = pageFetchResult.value

  if (pageFetchResultValue.customReturn) {
    queryClient.clear()
    return pageFetchResultValue.returnValue
  }

  await Promise.allSettled([
    prefetchCUELiveData({ queryClient, context }),
    prefetchSportData({
      queryClient,
      context,
    }),
    prefetchPlaylist({
      queryClient,
      context,
    }),
  ])

  initializeQueryClientKeys({ queryClient, context })

  pageFetchResultValue.injectExtraHeaders(context)

  const finalProps: CatchAllPageServerProps = {
    isBlickPlusOfferPage: getIsBlickPlusOfferPage(context),
    isError: false,
    keysThatShouldNotOverwriteClientCacheIfExists: processNonOverwritingKeyMap([
      ['isSwissGeolocation'],
      ['viewportType'],
      ['clientViewportType'],
      ['viewportOrientation'],
      ['viewportDimensions'],
      ['navigationSkeleton'],
      ['footerSkeleton'],
      ['videoSkeleton'],
      ['blickPlusSkeleton'],
      ['featureFlagsSkeleton'],
      ['disableThirdPartyScripts'],
      ['body-scroll'],
      ['is-immersive-header-active'],
    ]),
    dehydratedQueryState: dehydrate(queryClient),
    documentProps: {
      isSwissGeolocation: queryClient.getQueryData<boolean>([
        'isSwissGeolocation',
      ]) as boolean,
      disableThirdPartyScripts: !!queryClient.getQueryData<boolean>([
        'disableThirdPartyScripts',
      ]),
      preconnectToCUELiveDomain: !!queryClient.getQueryData<boolean>([
        'preconnect-domain-cue-live',
      ]),
      preconnectToSportDomain: !!queryClient.getQueryData<boolean>([
        'preconnect-domain-sport',
      ]),
      preconnectToSportDomainV2: !!queryClient.getQueryData<boolean>([
        'preconnect-domain-sport-v2',
      ]),
      kropkaConfig: composeKropkaConfig(queryClient),
    },
  }

  queryClient.clear()

  return {
    props: finalProps,
  }
}
